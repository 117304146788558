/**
 * Public Routes
 */
export const Home = "/";

export const Login =
  "https://passport.prod-everon.co.kr/realms/everon/protocol/openid-connect/auth?response_type=code&client_id=everon-contents-prod";

/**
 * Private Routes
 */
export const PrivateRoutes = {
  /**
   * 홈 관리
   */
  Banner: "/banner_management",
  BannerCreate: "/banner_management/create",
  BannerDetail: "/banner_management/detail",

  ChargingServiceDescription: "/charging_service_description_management",
  ChargingServiceDescriptionCreate:
    "/charging_service_description_management/create",
  ChargingServiceDescriptionDetail:
    "/charging_service_description_management/detail",

  /**
   * 전체보기 관리
   */
  Faq: "/faq_management",
  FaqCreate: "/faq_management/create",
  FaqDetail: "/faq_management/create",

  Event: "/event_management",
  EventCreate: "/event_management/create",
  EventDetail: "/event_management/detail",

  Notice: "/notice_management",
  NoticeCreate: "/notice_management/create",
  NoticeDetail: "/notice_management/detail",

  ChargerDescription: "/charger_description_management",
  ChargerDescriptionCreate: "/charger_description_management/create",
  ChargerDescriptionDetail: "/charger_description_management/detail",

  AppVersion: "/app_version_management",
  AppVersionCreate: "/app_version_management/create",
  AppVersionDetail: "/app_version_management/detail",

  /**
   * 약관 관리
   */
  Terms: "/terms_management",
  TermsCreate: "/terms_management/create",
  TermsDetail: "/terms_management/detail",

  /**
   * 미리보기
   */
  PreView: "/pre_view",
};

import { requestTermsDetail } from "@apis/terms";
import { TermsDetailResponse } from "@apis/terms/types";
import { ICONS } from "@assets/icons";
import Button from "@components/atoms/Button";
import ContentBox from "@components/atoms/ContentBox";
import IconBox from "@components/atoms/IconBox";
import Table from "@components/atoms/Table";
import { PrivateRoutes } from "@constants/urls";
import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import { ErrorResponseType } from "@network/types";
import useTermsDataStore from "@store/terms";
import { colors, typo } from "@styles/index";
import { RenderCommonElementByType } from "@utils/DynamicComponentUtils";
import { stringToDateFormatter } from "@utils/string-utils";
import { Pagination } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { TermsDetailData } from "./Data";

const TermsDetail: React.FC = () => {
  // Props
  const HEADER_TITLE = "약관 상세";
  const CONTENT_TITLE = "약관 정보";
  const SUB_CONTENT_TITLE = "약관 이력";
  const GIRD_COLUMN = 2;
  const TABLE_BUTTON_TEXT = "약관 이력 등록";

  // Data
  const {
    formInitialValues,
    forms: contentsOption,
    tableList: tableHeaderTitleData,
  } = TermsDetailData;

  // Hooks
  const { query, push } = useRouter();
  const { showTermsEditorModal } = useGlobalModal();
  const { setCategory } = useTermsDataStore((state) => state);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageFilter = JSON.parse(searchParams.get("page") as string);
  const pageSizeFilter = JSON.parse(searchParams.get("pageSize") as string);

  const [page, setPage] = useState<number>(pageFilter || 1);
  const [pageSize, setPageSize] = useState<number>(pageSizeFilter || 10);
  const [termsDetailData, setTermsDetailData] =
    useState<TermsDetailResponse | null>();

  const totalCount = termsDetailData?.termHistories?.length || 0;

  /** 약관 상세 리스트 */
  const termHistoriesList = termsDetailData?.termHistories?.map((item) => {
    const { postStartAt, postEndAt, createdAt, updatedAt, ...rest } = item;
    const today = moment().utc();
    const startDate = moment(postStartAt).utc();
    const endDate = moment(postEndAt).utc();
    const isPosting = today >= startDate && today <= endDate ? "✅" : "";
    return {
      ...rest,
      postDataAt: `${stringToDateFormatter(
        postStartAt,
      )} ~ ${stringToDateFormatter(postEndAt)} ${isPosting}`,
      updatedAt: createdAt,
    };
  });

  // Formik
  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: () => {},
  });

  const QUERY_KEY = ["termsDetail", page, pageSize];

  useQuery(QUERY_KEY, () => requestTermsDetail(query), {
    onSuccess: (data) => {
      if (!data) return;
      formik.setFieldValue("title", data.title);
      formik.setFieldValue("isRequired", data.isRequired ? "필수" : "선택");
      formik.setFieldValue(
        "isSignupTerms",
        data.isSignupTerms ? "노출" : "미노출",
      );
      formik.setFieldValue(
        "isServiceTerms",
        data.isServiceTerms ? "노출" : "미노출",
      );

      setTermsDetailData(data);
      setCategory(data.category);
    },
    onError: (error: ErrorResponseType) => {
      if (
        error.message.includes("Token is not active") ||
        error.message.includes(
          "Refresh toked issued before the client session started",
        )
      ) {
        return;
      } else {
        // showAlertModal({
        //   title: error?.message,
        // });
      }
    },
    enabled: !!query && searchParams.toString().includes("page"),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const handleGoBackClick = () => {
    push(PrivateRoutes.Terms);
  };

  const handleTermsEditorOpenModal = () => {
    if (!termsDetailData) return;
    showTermsEditorModal({
      title: termsDetailData.title,
    });
  };

  const handleChangePageSize = (page: number, pageSize: number) => {
    if (setPage === undefined || setPageSize === undefined) return;
    setPage(page);
    setPageSize(pageSize);
  };

  useEffect(() => {
    searchParams.set("page", String(page));
    searchParams.set("pageSize", String(pageSize));

    setSearchParams(searchParams);
  }, [page, pageSize, searchParams, setSearchParams]);

  return (
    <ContentsWrap>
      <HeaderWrap>
        <IconBox
          width={32}
          height={32}
          src={ICONS.ARROW_ICON.BACK}
          isCursorPointer
          onClick={handleGoBackClick}
        />
        <HeaderTitle>{HEADER_TITLE}</HeaderTitle>
      </HeaderWrap>

      <div>
        <InnerContentsWrap>
          <ContentBox style={{ padding: 0 }}>
            <StyledContentTitle>{CONTENT_TITLE}</StyledContentTitle>
            <InfoBoxWrap>
              <InfoBox girdColumn={GIRD_COLUMN}>
                {contentsOption?.map(({ label, ...res }) => {
                  return (
                    <CustomInputWrap key={label}>
                      <CustomLabel>{label}</CustomLabel>
                      {RenderCommonElementByType({
                        formik,
                        ...res,
                      })}
                    </CustomInputWrap>
                  );
                })}
              </InfoBox>
            </InfoBoxWrap>
          </ContentBox>

          <ContentBox style={{ padding: 0 }}>
            <TableTitleWrap>
              {SUB_CONTENT_TITLE}{" "}
              <Button
                buttonSize="small"
                text={TABLE_BUTTON_TEXT || "tableButtonText"}
                onClick={handleTermsEditorOpenModal}
              />
            </TableTitleWrap>

            <TableWrap>
              <Table
                tableContentsInfoData={tableHeaderTitleData}
                rowData={termHistoriesList || []}
                currentPageSize={pageSize}
                minHeight={300}
              />
              <TableBottomWrap>
                <ContentsTotal>
                  총<span>{totalCount}</span>
                </ContentsTotal>
                <PageNationWrap>
                  <Pagination
                    defaultCurrent={page}
                    defaultPageSize={pageSize}
                    total={totalCount}
                    showSizeChanger
                    locale={{ items_per_page: "개씩 보기" }}
                    current={page}
                    pageSize={pageSize}
                    onShowSizeChange={handleChangePageSize}
                    onChange={setPage}
                  />
                </PageNationWrap>
              </TableBottomWrap>
            </TableWrap>
          </ContentBox>
        </InnerContentsWrap>
      </div>
    </ContentsWrap>
  );
};

export default TermsDetail;

const ContentsWrap = styled.div`
  background-color: ${colors.GRAY11};
  height: 100%;
  padding: 40px;
  overflow: auto;
`;

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
`;

const MainHeaderTitle = styled.h1`
  ${typo.DISPLAY_3}
  color: ${colors.GRAY2};
  margin-bottom: 24px;
`;

const HeaderTitle = styled(MainHeaderTitle)`
  margin: 0;
`;

const TableTitle = styled.p`
  color: ${colors.GRAY2};
  ${typo.HEADING_4};
  margin-bottom: 18px;
`;

const InnerContentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ContentTitle = styled(TableTitle)`
  margin: 0;
  padding: 24px;
  border-bottom: 1px solid ${colors.GRAY9};
`;

const InfoBoxWrap = styled.div`
  padding: 40px;
`;

const CustomInputWrap = styled.div<{ flex?: number; minWidth?: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.GRAY9};
`;

const CustomLabel = styled.label`
  min-width: 130px;
  padding: 12px;
  background-color: ${colors.GRAY11};
  color: ${colors.GRAY4};
  ${typo.BODY_8};
`;

const StyledContentTitle = styled(ContentTitle)`
  padding: 24px;
  border-bottom: 1px solid ${colors.GRAY9};
`;

const FilterBox = styled.div<{ girdColumn?: number }>`
  width: 100%;
  margin-bottom: 12px;
  border-top: 1px solid ${colors.GRAY9};

  display: grid;
  grid-template-columns: repeat(${({ girdColumn }) => girdColumn}, 1fr);

  .faqTitle {
    grid-column: 1 / 3;
  }
`;

const InfoBox = styled(FilterBox)`
  margin-bottom: unset;
`;

const ContentsMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 840px;
  margin: auto;
  padding: 52px 0 80px;
`;

const TableWrap = styled(ContentsMainWrap)`
  /*  */
  padding: 40px 40px 80px;
  margin: unset;
  max-width: unset;
`;

const TableTitleWrap = styled(ContentTitle)`
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid ${colors.GRAY9};
`;

const TableBottomWrap = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const ContentsTotal = styled.span`
  display: flex;
  gap: 8px;
  color: ${colors.GRAY6};
  font-weight: 500;
  ${typo.BODY_8};

  span {
    ${typo.BODY_8};
    font-weight: 500;
    color: ${colors.GRAY2};
  }
`;

const PageNationWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
